import React from "react"
import { Card, Col, Row } from 'antd';
import withScreen from "../misc/withScreen"
import "./home.css"
let func = (props) => {
    return (
        <div style={{ width: "100%", height: props.isMobile()?"100%":"92.5%", background: "#ececec", padding: "15px" }}>
            <Row gutter={[10, 16]}

            >


                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {props.isMobile() ?
                            <img src="/img/145428.JPG" style={{ width: "100%" }} />
                        
                        :
                        <div style={{ height: "30vh", width: "100%", overflow: "hidden" }}>
                            <img src="/img/145428.JPG" style={{ width: "100%", maxHeight: "initial", marginTop: "-10%" }} />
                        </div>
                    }
                </Col>

                <Col xs={24} sm={24} md={20} lg={8} xl={8}>
                    <Card title="Wir arbeiten mit Produkten von:" bordered={false}>
                        <ul>
                            <li>Allpresan®</li>
                            <li>Remmeles-Propolis®</li>
                        </ul>
                    </Card>
                </Col>

                <Col xs={0} sm={0} md={2} lg={7} xl={7} >
                    <Card
                        title="Wir sind Mitglied im Verband Freier FußpflegerInnen"

                        bodyStyle={{ background: "url(/img/vff_logo.jpg) no-repeat center center" }}
                    >
                        <img src="/img/vff_logo.jpg" style={{ visibility: "hidden" }} />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={11} lg={9} xl={9}>
                    <Card title="Fachgerechte Fußpflege" bordered={false}>
                        <ul>
                            <li>mit 20-Jähriger Erfahrung</li>
                        </ul>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default withScreen(func)
import React,{useState} from 'react';
import './index.css';
import "./App.css"
import { Layout, Menu,Row,Col,Button,Drawer } from 'antd';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Anfahrt from "./components/anfahrt"
import Datenschutz from "./components/datenschutz"
import Impressum from "./components/impressum"
import Home from "./components/home"
import withScreen from "./misc/withScreen"
import {MenuOutlined } from "@ant-design/icons"


const { Header, Content } = Layout;
function App(props) {
  const [showDrawer, setDrawer] = useState(false);


  let setShowDrawer= ()=>{
    setDrawer(true)
  }
  let onClose=()=>{
    setDrawer(false)
  }
  return (
    <Router>
      <Layout style={{ background: "none", height: "100%" }}>
        {props.isMobile() ?
          <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <Row>
              <Col span={6}></Col>
              <Col span={16}>
                <img src="/logoschrift.png" alt="Logo" className="logo" />
              </Col>
              <Col span={2} style={{float:"right"}}>
              <Button className="barsMenu"  onClick={setShowDrawer} icon={<MenuOutlined />} ></Button>
              </Col>
            </Row>
            <Drawer
              title="Menü"
              placement="right"
              closable={false}
              onClose={onClose}
              visible={showDrawer}
              width="45%"
            >
              <Menu theme="light" mode="vertical">
              <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
              <Menu.Item key="2"><Link to="/anfahrt">Anfahrt</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/datenschutz">Datenschutz</Link></Menu.Item>
              <Menu.Item key="4"><Link to="/impressum" style={{whiteSpace: "normal"}}>Impressum</Link></Menu.Item>
              <Menu.Item key="5"><Link to="/impressum" style={{whiteSpace: "normal"}}>Kontakt</Link></Menu.Item>
            </Menu>
            </Drawer>
            
          </Header>
          :
          <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <img src="/logoschrift.png" alt="Logo" className="logo" />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
              <Menu.Item key="2"><Link to="/anfahrt">Anfahrt</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/datenschutz">Datenschutz</Link></Menu.Item>
              <Menu.Item key="4"><Link to="/impressum">Impressum / Kontakt</Link></Menu.Item>
            </Menu>
          </Header>}
        <Content className="site-layout" style={{ padding: '0 5%', marginTop: 73, height: "100%" }}>
          <Routes>
            <Route path="/anfahrt" element={<Anfahrt/>}>
            </Route>
            <Route path="/datenschutz" element={<Datenschutz/>}>

            </Route>
            <Route path="/impressum" element={<Impressum/>}>

            </Route>
            <Route path="/" element={<Home/>}>
            </Route>
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default withScreen(App);

import * as React from 'react';
import * as _ from 'lodash'

const ScreenType = {
    MOBILE : 1,
    TABLET : 2,
    DESKTOP : 3
  }
let defaultOptions = {
    mobileLimit: 468,
    tabletLimit: 768,
    shouldListenOnResize: true
  }
export default function withScreen(WrappedComp, options = defaultOptions) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onResize = _.throttle(this.onResize, 100);
      this.state = {
        currentSize: this.getSize(window.innerWidth)
      }
    }
    componentDidMount() {
      if (options.shouldListenOnResize) {
        window.addEventListener('resize', this.onResize);
      }
    }
    componentWillUnmount() {
      this.onResize.cancel()
      window.removeEventListener('resize', this.onResize);
    }

    onResize= () => {
      const newSize = this.getSize(window.innerWidth);
      if (newSize !== this.state.currentSize) {
        this.setState({
          currentSize: newSize
        });
      }
    }

    getSize(size) {
      if (size <= options.mobileLimit) {
        return ScreenType.MOBILE;
      } else if (size >= options.tabletLimit) {
        return ScreenType.DESKTOP;
      } else {
        return ScreenType.TABLET;
      }
    }

    isMobile = () => {
      return this.state.currentSize === ScreenType.MOBILE;
    }
    isTablet = () => {
      return this.state.currentSize === ScreenType.TABLET;
    }
    isDesktop = () => {
      return this.state.currentSize === ScreenType.DESKTOP;
    }
    render() {
      const detectMethods = {
        isMobile: this.isMobile,
        isTablet: this.isTablet,
        isDesktop: this.isDesktop
      }
      return <WrappedComp
        {...detectMethods}
        {...this.props}
      />
    }
  }
}
import React from "react"


export default function Impressum (){
    return(
        <>
        <p>MEDIPED e.K.<br />

Firmeninhaber: Petra Grallinger<br />Straubinger Straße 8<br />94405 Landau</p>

<p>Telefon: 09951/1823<br />

Telefax: 09951/601842<br />

E-Mail: <a href="mailto:mediped@t-online.de">mediped@t-online.de</a><br />

</p>

<p><strong>Registereintrag:</strong><br />Eingetragen im Handelsregister.<br />Registergericht: Landshut<br />Registernummer: HRA 9018</p><p><strong>Berufshaftpflichtversicherung: </strong>Volksfürsorge<br />

AMP AS<br />

Heilwesen Team<br />

Postfach 100514<br />

20003 Hamburg<br />Geltungsbereich: Deutschland</p><p><strong>Verantwortlich für den Inhalt</strong> (gem. § 55 Abs. 2 RStV):<br />Gerd Grallinger<br />

Straubinger Str. 8<br />

94405 Landau<br /></p>

<p><strong>Bildrechte:</strong><br />Eigenfotos Gerd Grallinger</p>

    </>)
}
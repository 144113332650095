import React from "react"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
export default function Anfahrt(){
    let config = {}
    config.params = {
        center: [48.677073, 12.689858],
        zoomControl: false,
        zoom: 17,
        maxZoom: 17,
        minZoom: 17,
        scrollwheel: false,
        legends: true,
        infoControl: false,
        attributionControl: true
    };
    let icon = L.icon({
        iconUrl: '/marker-icon.png',
        iconAnchor: [3, 30],
        popupAnchor: [9, -30],
        shadowUrl: '/marker-shadow.png',
    })
    return (
        <div style={{ height: "65vh", paddingTop: "0px" }}>
            <div>
                <h1 style={{fontWeight:"bold"}}>Anfahrt:</h1>
                MEDIPED e.K. <br />
                Straubinger Straße 8 <br />
                94405 Landau an der Isar
            </div>

            <MapContainer {...config.params} style={{ height: "65vh",marginTop:"15px" }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={config.params.center} icon={icon}  >
                    <Popup >
                        MEDIPED e.K. <br />
                    Straubinger Straße 8 <br />
                    94405 Landau an der Isar
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}